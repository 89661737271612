import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

// ICN
import { ReactComponent as DownloadIcn } from "../../../../../../Assets/Images/icn/download.svg";
import { ReactComponent as DeleteIcn } from "../../../../../../Assets/Images/icn/delete.svg";
import { ReactComponent as CopyIcn } from "../../../../../../Assets/Images/icn/Copy.svg";
import NewFormPopup from "../../Edit/Component/Modal/NewQuestion/NewQuestionPopup";
import DeletePopup from "components/Modals/DeletePopup/DeletePop";
import CreateFormPop from "../Modal/CreateFormPop";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { duplicateForm, getMyFormList } from "store/services/myFormService";
import { editForm, myForms, myFormsPreview } from "Routes/paths";
import { deleteMyForm, toggleDeleteFormPopup } from "store/actions/myForm";
import * as actions from "store/actions";
import MyFormsPDF from "../PDFReportComponents/MyFormsPDF";
import { FOOD_JOURNAL_FORM_ID, NAQV2_ID } from "common/constants";
import { UC_FIRST } from "helpers/common";

const FormListing = ({
  dispatch,
  forms,
  showDeleteFormPopup,
  deleteFormId,
  currentUserId,
  handleDuplicate,
  Columns,
  fetchFormList,
}) => {
  const navigate = useNavigate();
  const [NewFormPop, setNewFormPop] = useState("");
  const [deletePop, setDeletePop] = useState("");
  const handleNewForm = () => setNewFormPop(!NewFormPop);
  const openDeleteFormPopup = (formId = null) => {
    dispatch(
      toggleDeleteFormPopup({
        visible: true,
        formId,
      })
    );
  };
  // const handleCreateFormPop = () => setCreateFormPopup(!CreateFormPopup);

  return (
    <>
      <NewFormPopup NewFormPop={NewFormPop} setNewFormPop={setNewFormPop} />
      <DeletePopup
        showPopup={showDeleteFormPopup}
        formId={deleteFormId}
        fetchFormList={fetchFormList}
      />
      {/* <CreateFormPop
        duplicate={true}
        CreateFormPopup={CreateFormPopup}
        setCreateFormPopup={setCreateFormPopup}
      /> */}
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-transparent">
            <tr>
              <Columns />
            </tr>
          </thead>
          <tbody>
            {forms &&
              forms.length > 0 &&
              forms.map((form, idx) => {
                // if (form.id === NAQV2_ID) {
                //   return null;
                // }
                return (
                  <>
                    <tr>
                      <td className="p-3 border-0 ws-normal">
                        <b
                          className="theme-clr pointer"
                          onClick={() => navigate(myFormsPreview.replace(":formId", form.id))}
                        >
                          {form.form_name}
                        </b>
                      </td>
                      <td className="p-3 border-0 ws-normal">
                        <b
                          className="theme-clr pointer"
                        >
                          {UC_FIRST(form.form_type)}
                        </b>
                      </td>
                      <td className="p-3 border-0">
                        <p className="theme-clr m-0">
                          {moment(form.updated_at || form.created_at).format("M/DD/YYYY")}
                        </p>
                      </td>
                      <td className="p-3 border-0">
                        <div className="d-flex align-items-center gap-10">
                          <Button
                            title="View Form"
                            variant="transparent"
                            className="border-0 btn bg-tranpsparent p-0"
                            style={{ opacity: 0.6 }}
                            onClick={() => {
                              navigate(generatePath(myFormsPreview, { formId: form.id }));
                            }}
                          >
                            <i className="fa fa-lg fa-eye"></i>
                          </Button>
                          {form.created_by === currentUserId && (
                            <Link
                              to={editForm.replace(":formId", form.id)}
                              className="border-0 btn bg-tranpsparent p-0"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.5325 5.27992C15.825 4.98742 15.825 4.49992 15.5325 4.22242L13.7775 2.46742C13.5 2.17492 13.0125 2.17492 12.72 2.46742L11.34 3.83992L14.1525 6.65242M2.25 12.9374V15.7499H5.0625L13.3575 7.44742L10.545 4.63492L2.25 12.9374Z"
                                  fill="#A4A4A4"
                                />
                              </svg>
                            </Link>
                          )}
                          <Button
                            onClick={() => handleDuplicate(form.id)}
                            variant="transparent"
                            className="border-0 p-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M11.8125 12.9375V15.4688C11.8125 15.9345 11.4345 16.3125 10.9688 16.3125H3.65625C3.43247 16.3125 3.21786 16.2236 3.05963 16.0654C2.90139 15.9071 2.8125 15.6925 2.8125 15.4688V5.90625C2.8125 5.4405 3.1905 5.0625 3.65625 5.0625H5.0625C5.43943 5.06224 5.81573 5.09335 6.1875 5.1555M11.8125 12.9375H14.3438C14.8095 12.9375 15.1875 12.5595 15.1875 12.0938V8.4375C15.1875 5.0925 12.7552 2.31675 9.5625 1.7805C9.19073 1.71835 8.81443 1.68725 8.4375 1.6875H7.03125C6.5655 1.6875 6.1875 2.0655 6.1875 2.53125V5.1555M11.8125 12.9375H7.03125C6.80747 12.9375 6.59286 12.8486 6.43463 12.6904C6.27639 12.5321 6.1875 12.3175 6.1875 12.0938V5.1555M15.1875 10.125V8.71875C15.1875 8.04742 14.9208 7.40359 14.4461 6.92889C13.9714 6.45419 13.3276 6.1875 12.6562 6.1875H11.5312C11.3075 6.1875 11.0929 6.09861 10.9346 5.94037C10.7764 5.78214 10.6875 5.56753 10.6875 5.34375V4.21875C10.6875 3.88634 10.622 3.55719 10.4948 3.25008C10.3676 2.94298 10.1812 2.66394 9.94611 2.42889C9.71107 2.19384 9.43202 2.00739 9.12492 1.88018C8.81781 1.75297 8.48866 1.6875 8.15625 1.6875H7.3125"
                                stroke="#A4A4A4"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Button>
                          {form.created_by === currentUserId && (
                            <Button
                              onClick={() => {
                                openDeleteFormPopup(form.id);
                              }}
                              variant="transparent"
                              className="border-0 p-0"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M4.5 14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V6.75C13.5 5.925 12.825 5.25 12 5.25H6C5.175 5.25 4.5 5.925 4.5 6.75V14.25ZM13.5 3H11.625L11.0925 2.4675C10.9575 2.3325 10.7625 2.25 10.5675 2.25H7.4325C7.2375 2.25 7.0425 2.3325 6.9075 2.4675L6.375 3H4.5C4.0875 3 3.75 3.3375 3.75 3.75C3.75 4.1625 4.0875 4.5 4.5 4.5H13.5C13.9125 4.5 14.25 4.1625 14.25 3.75C14.25 3.3375 13.9125 3 13.5 3Z"
                                  fill="#A4A4A4"
                                />
                              </svg>
                            </Button>
                          )}
                          {![FOOD_JOURNAL_FORM_ID].includes(form.id) && (
                            <MyFormsPDF formID={form.id} />
                          )}
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    showDeleteFormPopup: state.MyForm.deleteFormPopup.visible,
    deleteFormId: state.MyForm.deleteFormPopup.formId,
    currentUserId: state?.Auth?.user?.id || null,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FormListing);
